<template>
  <div>
    <div v-if="isError">
        <not-found-page/>
      </div>
    <div v-else>   
    <div class="access-header py-3">
      
      <div class="container">
        <div class="row align-itmes-center">
          <div class="col-xl-2">
            <div>
              <img src="../assets/images/NCENA.png"  class="elite-logo"  alt="Logo">
            </div>
          </div>
          <div class="col-xl-10">
            <ul>
              <li>
                <a :href="menuLink.routeToMemberDashboard" target="_blank"
                ><span> MEMBER DASHBOARD</span></a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="parent-wrap"
    style="
    background: url('https://corenroll.com/biz_image.php?file=test_1660137952_iStock-1279487333.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    ">
      <div class="container ">
          <!-- Note: For Group Case Only
                Logo refers to image and image refers to logo
                Logic was changed due to api switch logo in place of images vice-versa.
            -->
        <div class="row" v-if="showAcessCode">
          <div class="col-md-8 access-container">
            <img src="../assets/images/lock.svg"/>
            <p>Please Enter Your Access Code,<br> to access site</p>
            <input   v-model="repAccessCode"
                    @input="removeRepErrorMessage();"
                    :class="{'form-control is-invalid': $v.repAccessCode.$error || repErrorFlag}"
                    class="custom-input"
                    placeholder="Enter Rep Access Code Eg: ABCD1212" />
            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.repAccessCode.required && $v.repAccessCode.$error">
              Field is required
            </div>
            <div class="error_message mt-2" v-if="repErrorFlag === true">{{ this.repErrorMsg }}</div>
            <b-button @click="checkRepAccessCode" class="submit-btn mt-3">Submit Access Code</b-button>
          </div>
        </div>

        <div  class="row"  v-if="!showAcessCode">
          <b-container>
            <div class="col-md-8 access-container">
              <div class="popUp-header conform-header" style="border-bottom: 0px !important ">
                <div class="unlock-header">
                  <img src="../assets/images/unlock.svg" alt="unlock"> <h4> Please confirm that you are accessing the site with the correct representative access
                  code.</h4>
                </div>
              </div>
              <div class="popUp-details">
                <div valign="top"><b>{{repInfo.firstName}} {{repInfo.lastName}}</b> <br>{{repInfo.repCode}}<br>{{repInfo.email}}<br>{{repInfo.phoneNumber}}
                </div>
                <div class="access-action-btn">
                    <input type="submit"
                          @click="routeToRepEnroll"
                          name="submit_Agent"
                          id="submit_Agent"
                          value="Confirm"
                          class="btn btn-success mr-xs mb-sm mb-0" />
                    <input type="submit"
                          name="submit_Agent"
                          @click="showAcessCode = true"
                          id="submit_Agent"
                          value="Cancel"
                          class="btn btn-cancel mr-xs mb-sm mb-0" />
                  <div>
                  </div>
                </div>
              </div>
            </div>
          </b-container>
        </div>

         <!-- Site Footer access code-->
<!--        <div class="access-footer">
          <div  class="core-logo">
            <img src="../assets/images/core-logo.png" >
          </div>
          <div class="access-menu d-flex justify-content-center" style="margin: auto">
            <ul class="access-ul">
              <li>
                <a :href="menuLink.routeToGroupRegistration" target="_blank"
                ><span> GROUP APPLICATION</span></a
                >
              </li>
              <li v-b-modal.employeeEnrollment-pop
                  @click="resetEnrollGroup()">
                EMPLOYEE ENROLLMENT
              </li>
              <li>
                <a :href="menuLink.routeToRepRegistration" target="_blank"
                ><span> REP REGISTRATION</span></a
                >
              </li>
              <li>
                <a :href="menuLink.routeToRepDashboard" target="_blank"
                ><span> REP DASHBOARD</span></a
                >
              </li>
              <li>
                <a :href="menuLink.routeToMemberDashboard" target="_blank"
                ><span> MEMBER DASHBOARD</span></a
                >
              </li>
              <li @click="routeToNotMySITE" style="cursor: pointer">NOT MY SITE </li>
            </ul>
          </div>
          <div class="copyright">
            <p >Copyright &copy; 2023 </p>
            <p class="copyright-left"> </p>
            <p>All Rights Reseved</p>
          </div>
        </div>-->
        <!-- Employee Enrollment pop up -->
        <b-modal
            id="employeeEnrollment-pop"
            centered
            size="md"
            v-model="enrollmentShow"
        >
          <template #modal-header>
            <div class="popUp-header">
              <h4>Employee Enrollment {{ this.groupErrorMsg }}</h4>
              <b-button variant="close-btn" @click="enrollmentShow = false">
                <i class="fas fa-times"></i>
              </b-button>
            </div>
          </template>
          <b-container fluid>
            <div v-if="!showProceedConformation">
              <div class="form-group mb-3">
                <label>Group Enrollment Code</label>
                <input
                    type="text"
                    v-model="groupEnrollmentCode"
                    @input="removeGroupErrorMessage()"
                    :class="{
                    'form-control is-invalid':
                      $v.groupEnrollmentCode.$error || groupErrorFlag,
                  }"
                    class="custom-input"
                    placeholder="Enter Group Enrollment CodeEg: ABCD1212"
                />
                <div
                    class="form-group__message text-left ml-3 mt-2"
                    v-if="
                    !$v.groupEnrollmentCode.required &&
                    $v.groupEnrollmentCode.$error
                  "
                >
                  Field is required 
                </div>
                <div class="error_message mt-2" v-if="groupErrorFlag === true">
                  {{ this.groupErrorMsg }}
                </div>
              </div>
              <div class="form-group semiText">
                <p>
                  If you don't know your group enrollment code please contact your
                  company's human resource department. 
                </p>
              </div>
              <div class="form-group">
                <button class="univ-btn" @click="checkGroupEnrollmentCode()">
                  Continue
                </button>
              </div>
            </div>
            <div v-if="showProceedConformation">
              <div class="text-left mb-3">
                <div class="mt-2">
                  The company that you will be enrolling under is:<strong
                    style="color: #28a745"
                >
                  {{ groupInfo.name }}</strong
                >
                </div>
                <div
                    class="mt-2 group-not-employer-container"
                    @click="backToEnrollmentCode"
                >
                  <p>
                    If this is NOT the your employer please click here, to re-enter
                    your employer enrollment code.
                  </p>
                  <p>
                    If you have questions please contact your Employer for correct
                    code.
                  </p>
                </div>
              </div>
              <b-row>
                <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <div class="form-group">
                    <button class="univ-btn" @click="routeToGroupEnroll">
                      Yes
                    </button>
                  </div>
                </b-col>
                <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <button class="univ-btn" @click="resetEnrollGroup">No</button>
                </b-col>
              </b-row>
            </div>
          </b-container>
        </b-modal>
      </div>
    </div>
    <div>
       <access-footer></access-footer>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import {required} from "vuelidate/lib/validators";
import store  from '../store'
import {MUTATION_SHOW_LOADING_SPINNER, MUTATION_LOGIN} from "../store";
// import Api from "../includes/Api";
import NotFoundPage from '../components/NotFound.vue'
export default {
  name: "AccessPage",
  components:{
    NotFoundPage
  },
  data () {
    return {
      showAcessCode: true,
      repAccessCode: null,
      repRouteName: null,
      defaultFooter: true,
      repErrorFlag: false,
      repErrorMsg:'',
      repInfo: {
        firstName: null,
        lastName: null,
        email: null,
        repCode: null,
        phoneNumber: null,
        logo: null,
        image: null,
      },
      logo: null,
      image: null,
      phoneNumber: null,
      email: null,
      repName: null,
      groupName: null,
      agentInfo: null,
      showProceedConformation: false,
      enrollmentShow: false,
      groupEnrollmentCode: null,
      groupErrorFlag: false,
      groupErrorMsg:'',
      groupInfo: {
        name: "",
      },
      groupEmployeeInfo: null,
      menuLink: {
        routeToRepDashboard: process.env.VUE_APP_REP_DASHBOARD,
        routeToMemberDashboard: process.env.VUE_APP_MEMBER_DASHBOARD,
        routeToRepRegistration: process.env.VUE_APP_REP_REGISTRATION,
        routeToGroupRegistration: process.env.VUE_APP_GROUP_REGISTRATION,
      }
    }
  },
  validations: {
    repAccessCode: {
      required
    },
    groupEnrollmentCode: {
      required
    }
  },
  computed: {
    landingInfo () {
      return this.$store.getters.getLandingInfo
    },
    landingUrl () {
      return this.$store.getters.getLandingUrl
    },
    isError(){
      return store.state.isError;
    }
  },
  mounted () {
    let app = this
    store.commit(MUTATION_SHOW_LOADING_SPINNER,false);
    app.$store.dispatch("isEmployeeEnrollment", false);
    app.checkLandingUrl();
    app.getGroupHeaderInfo();
    app.getAgentHeaderInfo();
  },
  methods: {
    checkRepAccessCode () {
      let app = this
      app.$v.repAccessCode.$touch();
      if (app.$v.repAccessCode.$invalid) {
        return false
      } else {
        axios.defaults.headers.common['Authorization'] = 'Basic ' + process.env.VUE_APP_CLIENT_API_TOKEN;
        let app = this
        axios.get(process.env.VUE_APP_API_BASE+'/validate-agent', {
          params: {
            agent_code: app.repAccessCode
          },
        })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.repInfo.firstName = response.data.data.first_name
              app.repInfo.lastName = response.data.data.last_name
              app.repInfo.email = response.data.data.email
              app.repInfo.phoneNumber = response.data.data.phone
              app.repInfo.repCode = response.data.data.agent_code
              app.repInfo.logo = response.data.data.logo
              app.repInfo.image = response.data.data.image
              app.showRepInfo();
              if (app.landingInfo.type !== 'agent') {
                app.agentInfo = response.data.data
              }
            }
            if (response.status == 204) {
              app.repErrorFlag = true
              app.showRepConformation = false
            }
          })
          .catch(function (error) {
            app.repErrorFlag = true
            app.repErrorMsg=error.response.data.message
            app.showRepConformation = false
          });
      }
    },
    routeToRepEnroll () {
      console.log('inside route to rep')
      let app = this
      axios.defaults.headers.common['Authorization'] = 'Basic ' + process.env.VUE_APP_CLIENT_API_TOKEN;
      axios.get(process.env.VUE_APP_API_BASE+'/validate-agent', {
        params: {
          agent_code: app.repAccessCode
        },
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              store.commit(MUTATION_SHOW_LOADING_SPINNER,true);
              app.$store.dispatch('isRepAuthenticated', true)
              let repInfo = response.data.data
              app.repRouteName = repInfo.landing_url
              let enrollType = 'Agent'
              let redirectionUrl = 'accessPage'
              if (repInfo.landing_url == null || repInfo.landing_url == '') {
                app.$router.push("/landing-config-error/" + enrollType + '/' + redirectionUrl);
              } else {
               // app.$router.push(`/${app.repRouteName}`)
                if (!app.landingUrl) {
                  store.dispatch('landingUrl', app.repRouteName)
                }
                if (app.landingInfo) {
                  if (app.landingInfo.type !== 'agent') {
                    app.$store.dispatch('repInfo', app.agentInfo)
                  }
                  if (app.landingUrl && app.landingInfo.type === 'agent') {
                    store.dispatch('landingUrl', encodeURIComponent(app.repRouteName))
                  }
                }
                app.$router.go();
              }
              store.commit(MUTATION_LOGIN, repInfo);
              store.commit(MUTATION_SHOW_LOADING_SPINNER,false);
            }
            if (response.status == 204) {
              app.repErrorFlag = true
              app.showRepConformation = false
            }
          })
          .catch(function (error) {
            store.commit(MUTATION_SHOW_LOADING_SPINNER,false);
            app.repErrorFlag = true
            app.repErrorMsg=error.response.data.message
            app.showRepConformation = false
          });
    },
    showRepInfo () {
      let app = this
      app.showAcessCode = false
    },
    removeRepErrorMessage () {
      let app = this
      app.repErrorFlag = false
    },
    getGroupHeaderInfo () {
      let app = this;
      if (app.landingInfo) {
        if (app.landingInfo.type !== 'agent') {
          console.log('inside get group header info')
          axios.defaults.headers.common['Authorization'] = 'Basic ' + process.env.VUE_APP_CLIENT_API_TOKEN;
          axios.get(process.env.VUE_APP_API_BASE + '/get-group-landing-page-info', {
            params: {
              group_id: app.landingInfo.group_id
            },
          })
              .then(function (response) {
                app.clearLandingInfo();
                if (response.data.data.image !== null) {
                  app.logo = response.data.data.image;
                }
                app.email = response.data.data.display_email;
                app.phoneNumber = response.data.data.display_phone;
                app.groupName = response.data.data.display_name;
                if (response.data.data.logo !== null) {
                  app.image = response.data.data.logo;
                }
              })
              .catch(function (error) {
                console.log(error);
              });
        }
      }
    },
    getAgentHeaderInfo () {
      let app = this;
      if (app.landingInfo) {
        if (app.landingInfo.type !== 'group') {
          console.log('inside get agent header info')
          app.landing = {};
          axios.defaults.headers.common['Authorization'] = 'Basic ' + process.env.VUE_APP_CLIENT_API_TOKEN;
          axios.get(process.env.VUE_APP_API_BASE + '/get-agent-landing-page-info', {
            params: {
              agent_id: app.landingInfo.agent_id
            },
          })
              .then(response => {
                app.clearLandingInfo();
                if (response.data.data.logo !== null) {
                  app.logo = response.data.data.logo;
                }
                app.email = response.data.data.display_email;
                app.phoneNumber = response.data.data.display_phone;
                app.repName = response.data.data.display_name;
                if (response.data.data.image !== null) {
                  app.image = response.data.data.image;
                }
                console.log('inside get agent header info', app.logo, app.email)
              })
              .catch((err) => {
                console.log(err);
              });
        }
      }
    },
    clearLandingInfo () {
      let app = this
      app.logo = null,
      app.image = null,
      app.phoneNumber =null,
      app.email  = null,
      app.repName = null,
      app.groupName = null
    },
    resetEnrollGroup() {
      let app = this;
      app.showProceedConformation = false;
      app.enrollmentShow = false;
      app.groupEnrollmentCode = null;
      app.groupErrorFlag = false;
      app.$v.$reset();
    },
    checkGroupEnrollmentCode() {
      let app = this;
      app.$v.groupEnrollmentCode.$touch();
      if (app.$v.groupEnrollmentCode.$invalid) {
        return false;
      } else {
        axios.defaults.headers.common['Authorization'] = 'Basic ' + process.env.VUE_APP_CLIENT_API_TOKEN;
        axios.get(process.env.VUE_APP_API_BASE + "/validate-group", {
              params: {
                group_code: app.groupEnrollmentCode,
              },
            })
            .then(function (response) {
              if (response.data.status == "success") {
                app.groupInfo.name = response.data.data.name;
                app.showProceedConformation = true;
                app.fetchGroupInformation(response.data.data.group_id);
              }
              if (response.status == 204) {
                app.groupErrorFlag = true;
                app.showProceedConformation = false;
              }
            })
            .catch(function (error) {
              app.groupErrorFlag = true;
              app.groupErrorMsg=error.response.data.message
              app.showProceedConformation = false;
            });
      }
    },
    fetchGroupInformation (groupId) {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Basic ' + process.env.VUE_APP_CLIENT_API_TOKEN;
      axios.get(process.env.VUE_APP_API_BASE + '/get-group-landing-page-info', {
        params: {
          group_id: groupId
        },
      })
        .then(function (response) {
          app.groupEmployeeInfo = response.data.data
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    routeToGroupEnroll() {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Basic ' + process.env.VUE_APP_CLIENT_API_TOKEN;
      axios.get(process.env.VUE_APP_API_BASE + "/validate-group", {
            params: {
              group_code: app.groupEnrollmentCode,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              store.commit(MUTATION_SHOW_LOADING_SPINNER,true);
              app.$store.dispatch("isEmployeeEnrollment", true);
              let responseData = response.data.data;
              app.groupRouteName = responseData.landing_url;
              app.enrollmentShow = false;
              app.$store.dispatch('isRepAuthenticated', true)
              let enrollType = "Group";
              let redirectionUrl = 'accessPage'
              if (responseData.landing_url == null || responseData.landing_url == "") {
                app.$router.push("/landing-config-error/" + enrollType + '/' + redirectionUrl);
              } else {
              app.$router.push({name: 'main'})
              app.$store.dispatch("landingInfo", null);
              app.$store.dispatch("repInfo", null);
              app.$store.dispatch("groupInfo", null);
              app.$store.dispatch('landingUrl', encodeURIComponent(app.groupRouteName))
              app.$store.commit('MUTATION_SET_ERROR', false);
              // app.$store.dispatch('groupInfo', app.groupEmployeeInfo)
              app.$router.go();
              }
              store.commit(MUTATION_SHOW_LOADING_SPINNER,true);
            }
            if (response.status == 204) {
              app.groupErrorFlag = true;
              app.showProceedConformation = false;
            }
          })
          .catch(function (error) {
            console.log(error)
            store.commit(MUTATION_SHOW_LOADING_SPINNER,false);
            app.groupErrorFlag = true;
            app.groupErrorMsg=error.response.data.message
            app.showProceedConformation = false;
          });
    },
    removeGroupErrorMessage() {
      let app = this;
      app.groupErrorFlag = false;
      app.repErrorFlag = false;
    },
    backToEnrollmentCode() {
      let app = this;
      app.showProceedConformation = false;
      app.groupEnrollmentCode = null;
      app.$v.$reset();
    },
    routeToNotMySITE() {
      let app = this;
      localStorage.clear();
      app.$router.push("/");
      app.$router.go();
      app.$store.dispatch("isRepAuthenticated", false);
      app.$store.dispatch("landingInfo", null);
      app.$store.dispatch("landingUrl", null);
      app.$store.dispatch("repInfo", null);
      app.$store.dispatch("groupInfo", null);
      app.$store.commit('MUTATION_SET_ERROR', false);
    },
    checkLandingUrl () {
      let app = this
      if (app.landingUrl === 'landing-config-error/Group/accessPage' && !app.$store.getters.isRepAuthenticated) {
        app.$store.dispatch("landingUrl", null);
      }
    }
  },
  watch: {
    landingInfo: function () {
      let app = this
      let landingParams = window.location.pathname.slice(1)
      if (landingParams !== app.$store.getters.getLandingUrl) {
        if (app.landingInfo.type === 'group') {
          app.getGroupHeaderInfo();
        }
        if (app.landingInfo.type === 'agent') {
          app.getAgentHeaderInfo();
        }
      }
    }
  }
}
</script>

<style lang="scss">
a {
  color: #212529;
  &:hover {
    color: #212529;
   }
}
.access-image {
  justify-content: space-evenly;
}
.portal-info{
  margin-right: 25%;
}
.img-agent-access{
  max-width: 74px;
  border-radius: 50%;
}

</style>