<template>
  <div>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-xxl-6 col-xl-6 col-lg-7 col-md-8 col-12 mb-4">
            <div class="foot-logo mb-3" :class="{'logo-align': !authState}">
              <img :src="footerIcon" alt="" />
            </div>
            <div v-if="siteUrl === 'true'">
              <div class="link-container">
                <b-row class="mt-3">
                  <b-col cols="12" sm="4" md="4" xl="4" lg="4" class="mb-3">
                    <div>BROKERS/REPS</div>
                    <div>
                      <a :href="menuLink.routeToRepRegistration" target="_blank"
                      ><span>- REP REGISTRATION</span></a
                      >
                    </div>
                    <div>
                      <a :href="menuLink.routeToRepDashboard" target="_blank"
                      ><span>- REP DASHBOARD</span></a
                      >
                    </div>
                    <div>
                      <span @click="routeToNotMySITE">- NOT MY SITE</span>
                    </div>
                  </b-col>
                  <b-col cols="12" sm="4" md="4" xl="4" lg="4" class="mb-3">
                    <div>EMPLOYEE/MEMBERS</div>
                    <div>
                      <span
                          v-b-modal.footerEmployeeEnrollment-pop
                          @click="resetEnrollGroup()"
                      >- EMPLOYEE ENROLL</span
                      >
                    </div>
                    <div>
                      <a
                          :href="menuLink.routeToMemberRegistration"
                          target="_blank"
                      ><span>- MEMBER LOGIN</span></a
                      >
                    </div>
                    <div>
                      <a :href="menuLink.routeToMemberDashboard" target="_blank"
                      ><span>- MEMBER DASHBOARD</span></a
                      >
                    </div>
                  </b-col>
                  <b-col cols="12" sm="4" md="4" xl="4" lg="4" class="mb-3">
                    <div>EMPLOYER/GROUPS</div>
                    <div>
                      <a
                          :href="menuLink.routeToGroupRegistration"
                          target="_blank"
                      ><span>- GROUP APPLICATION</span></a
                      >
                    </div>
                    <div>
                      <a :href="menuLink.routeToGroupDashboard" target="_blank"
                      ><span>- GROUP LOGIN</span></a
                      >
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div class="col-xxl-6 col-xl-6 col-lg-5 col-md-4 col-12 mb-4">
            <p>Download The Member App</p>
            <div class="app-icon">
              <a v-bind:href="iosLink" target="_blank"
              ><img :src="iosImgLink" alt="iOS App" class="img-fluid"
              /></a>
              <a
                  v-bind:href="androidLink"
                  v-if="googlePlay == true"
                  target="_blank"
              ><img :src="androidImgLink" alt="Android App" class="img-fluid"
              /></a>
            </div>
            <p>Click the Appropriate App</p>
            <p>
              For assistance or to report an issue, please submit a support request:
            </p>
            <a v-bind:href="ticket" class="ticket-btn" target="_blank"
            >Submit Support Request</a
            >
          </div>
          <div class="col-xxl-12">
            <p class="copyright">Copyright &copy; 2023 | All Rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>

    <!-- Employee Enrollment pop up -->
    <b-modal
        id="footerEmployeeEnrollment-pop"
        centered
        size="md"
        v-model="enrollmentShow"
    >
      <template #modal-header>
        <div class="popUp-header">
          <h4>Employee Enrollment</h4>
          <b-button variant="close-btn" @click="enrollmentShow = false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div v-if="!showProceedConformation">
          <div class="form-group mb-3">
            <label>Group Enrollment Code</label>
            <input
                type="text"
                v-model="groupEnrollmentCode"
                @input="removeGroupErrorMessage()"
                :class="{
                'form-control is-invalid':
                  $v.groupEnrollmentCode.$error || groupErrorFlag,
              }"
                class="custom-input"
                placeholder="Enter Group Enrollment CodeEg: ABCD1212"
            />
            <div
                class="form-group__message text-left ml-3 mt-2"
                v-if="
                !$v.groupEnrollmentCode.required &&
                $v.groupEnrollmentCode.$error
              "
            >
              Field is required
            </div>
            <div class="error_message mt-2" v-if="groupErrorFlag === true">
             {{ this.groupErrorMsg }}
            </div>
          </div>
          <div class="form-group semiText">
            <p>
              If you don't know your group enrollment code please contact your
              company's human resource department.
            </p>
          </div>
          <div class="form-group">
            <button class="univ-btn" @click="checkGroupEnrollmentCode()">
              Continue
            </button>
          </div>
        </div>
        <div v-if="showProceedConformation">
          <div class="text-left mb-3">
            <div class="mt-2">
              The company that you will be enrolling under is:<strong
                style="color: #28a745"
            >
              {{ groupInfo.name }}</strong
            >
            </div>
            <div
                class="mt-2 group-not-employer-container"
                @click="backToEnrollmentCode"
            >
              <p>
                If this is NOT the your employer please click here, to re-enter
                your employer enrollment code.
              </p>
              <p>
                If you have questions please contact your Employer for correct
                code.
              </p>
            </div>
          </div>
          <b-row>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <div class="form-group">
                <button class="univ-btn" @click="routeToGroupEnroll">
                  Yes
                </button>
              </div>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <button class="univ-btn" @click="resetEnrollGroup">No</button>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import store, {MUTATION_SHOW_LOADING_SPINNER} from "../store";
export default {
  name: "SiteFooter",

  data: function () {
    return {
      showAcessCode: false,
      phone: "Loading..",
      email: "Loading..",
      enrollmentShow: false,
      accssShow: false,
      ticket: "",
      footerIcon: "https://benefit-store-assets.s3.us-east-2.amazonaws.com/foot-logo.png",
      iosImgLink: "https://benefit-store-assets.s3.us-east-2.amazonaws.com/ios_icon.png",
      androidImgLink: "https://benefit-store-assets.s3.us-east-2.amazonaws.com/ios_icon.png",
      iosLink: "https://apps.apple.com/us/app/corenroll-member/id1539841827",
      androidLink:
          "https://play.google.com/store/apps/details?id=com.neura.corenroll_member&hl=en&gl=US",
      open_type: "",
      group_id: "",
      agent_id: "",
      googlePlay: true,
      siteUrl: process.env.VUE_APP_IS_GO_ENROLL,
      showProceedConformation: false,
      groupEnrollmentCode: null,
      groupRouteName: null,
      groupErrorFlag: false,
      groupErrorMsg:'',
      showRepConformation: false,
      repAccessCode: null,
      repRouteName: null,
      repErrorFlag: false,
      routeToGroupRegistration: process.env.VUE_APP_GROUP_REGISTRATION,
      routeToMemberDashboard: process.env.VUE_APP_MEMBER_DASHBOARD,
      repInfo: {
        firstName: "",
        lastName: "",
      },
      groupInfo: {
        name: "",
      },
      menuLink: {
        routeToAdmin: process.env.VUE_APP_ADMIN,
        routeToMessageCenter: process.env.VUE_APP_MESSAGE_CENTER,
        routeToFileManagement: process.env.VUE_APP_FILE_MANAGEMENT,
        routeToRepDashboard: process.env.VUE_APP_REP_DASHBOARD,
        routeToMemberDashboard: process.env.VUE_APP_MEMBER_DASHBOARD,
        routeToGroupDashboard: process.env.VUE_APP_GROUP_DASHBOARD,
        routeToTicketManagement: process.env.VUE_APP_TICKET_MANAGEMENT,
        routeToSos: process.env.VUE_APP_SOS,
        routeToRepRegistration: process.env.VUE_APP_REP_REGISTRATION,
        routeToMemberRegistration: process.env.VUE_APP_MEMEBER_REGISTRATION,
        routeToGroupRegistration: process.env.VUE_APP_GROUP_REGISTRATION,
      },
      show: false,
      videoLink: "",
      modelId: "",
      showAccessPopUp: false,
    };
  },
  validations: {
    groupEnrollmentCode: {
      required,
    },
  },
  computed: {
    authState () {
      return this.$store.getters.isRepAuthenticated
    },
  },
  mounted() {
    let app = this;
    if (
        app.$device.ios ||
        app.$device.iphone ||
        app.$device.iphoneX ||
        app.$device.iPhoneXR ||
        app.$device.iPhoneXSMax ||
        app.$device.ipod ||
        app.$device.ipad ||
        navigator.platform.indexOf("Mac") > -1
    ) {
      app.googlePlay = false;
    }
    app.fetchCatchInfo();
    if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
      app.getTicketLink();
    }
  },
  methods: {
    showDetailPage() {
      let app = this;
      app.showAcessCode = true;
      app.showAccessPopUp = true;
    },
    getTicketLink: function () {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Basic ' + process.env.VUE_APP_CLIENT_API_TOKEN;
      axios.get(process.env.VUE_APP_API_BASE + "/get-ticket-link")
          .then(function (response) {
            app.ticket = response.data.data["ticket"];
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    fetchCatchInfo() {
      let siteDomain = window.btoa(
          unescape(encodeURIComponent(process.env.VUE_APP_SITE_DOMAIN))
      );
      // let siteDomain = 'aHR0cHM6Ly9lbnJvbGwucHVyZW5yb2xsLmNvbQ=='
      let url = `${process.env.VUE_APP_API_BASE}/get-external-site-dynamic-information?site_domain=${siteDomain}`;
      caches
          .open("fetch_color_cache")
          .then((cache) => {
            cache.match(url).then((response) => {
              response.json().then((data) => {
                let getCatchColor = [];
                let newObj = {};
                getCatchColor = data;
                getCatchColor.map((item) => {
                  newObj[item.key] = item.value;
                });
                this.footerIcon = newObj["VUE_APP_FOOTER_LOGO"];
                this.iosImgLink = newObj["VUE_APP_IOS_ICON"];
                this.androidImgLink = newObj["VUE_APP_ANDROID_ICON"];
              });
            });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    checkGroupEnrollmentCode() {
      let app = this;
      app.$v.groupEnrollmentCode.$touch();
      if (app.$v.groupEnrollmentCode.$invalid) {
        return false;
      } else {
        axios.defaults.headers.common['Authorization'] = 'Basic ' + process.env.VUE_APP_CLIENT_API_TOKEN;
        axios.get(process.env.VUE_APP_API_BASE + "/validate-group", {
              params: {
                group_code: app.groupEnrollmentCode,
              },
            })
            .then(function (response) {
              if (response.data.status == "success") {
                app.groupInfo.name = response.data.data.name;
                app.showProceedConformation = true;
              }
              if (response.status == 204) {
                app.groupErrorFlag = true;
                app.showProceedConformation = false;
              }
            })
            .catch(function (error) {
            app.groupErrorFlag = true;
            app.groupErrorMsg=error.response.data.message
            app.showProceedConformation = false;
            });
      }
    },
    resetEnrollGroup() {
      let app = this;
      app.showProceedConformation = false;
      app.enrollmentShow = false;
      app.groupEnrollmentCode = null;
      app.groupErrorFlag = false;
      app.$v.$reset();
    },
    routeToGroupEnroll() {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Basic ' + process.env.VUE_APP_CLIENT_API_TOKEN;
      axios.get(process.env.VUE_APP_API_BASE + "/validate-group", {
        params: {
          group_code: app.groupEnrollmentCode,
        },
      })
          .then(function (response) {
            if (response.data.status == "success") {
              store.commit(MUTATION_SHOW_LOADING_SPINNER,true);
              app.$store.dispatch("isEmployeeEnrollment", true);
              let responseData = response.data.data;
              app.groupRouteName = responseData.landing_url;
              app.enrollmentShow = false;
              app.$store.dispatch('isRepAuthenticated', true)
              let enrollType = "Group";
              let redirectionUrl = 'accessPage'
              if (responseData.landing_url == null || responseData.landing_url == "") {
                app.$router.push("/landing-config-error/" + enrollType + '/' + redirectionUrl);
              } else {
                app.$router.push({name: 'main'})
                app.$store.dispatch("landingInfo", null);
                app.$store.dispatch("repInfo", null);
                app.$store.dispatch("groupInfo", null);
                app.$store.dispatch('landingUrl', encodeURIComponent(app.groupRouteName))
                // app.$store.dispatch('groupInfo', app.groupEmployeeInfo)
                app.$router.go();
              }
              store.commit(MUTATION_SHOW_LOADING_SPINNER,true);
            }
            if (response.status == 204) {
              app.groupErrorFlag = true;
              app.showProceedConformation = false;
            }
          })
          .catch(function (error) {
            store.commit(MUTATION_SHOW_LOADING_SPINNER,false);
            app.groupErrorFlag = true;
            app.groupErrorMsg=error.response.data.message
            app.showProceedConformation = false;
          });
    },
    removeGroupErrorMessage() {
      let app = this;
      app.groupErrorFlag = false;
      app.repErrorFlag = false;
    },
    routeToNotMySITE() {
      let app = this;
      localStorage.clear();
      app.$router.push("/");
      app.$router.go();
      app.$store.dispatch("isRepAuthenticated", false);
      app.$store.dispatch("landingInfo", null);
      app.$store.dispatch("landingUrl", null);
      app.$store.dispatch("repInfo", null);
      app.$store.dispatch("groupInfo", null);
      app.$store.dispatch("isPremierPlanSelected", false);
      app.$store.dispatch("isPremierPlanOnCart", false);
      app.$store.dispatch("zipCode", null);
    },
    backToEnrollmentCode() {
      let app = this;
      app.showProceedConformation = false;
      app.groupEnrollmentCode = null;
      app.$v.$reset();
    },
  },
  listener: {
    FromDirect() {
      let app = this;
      app.group_id = window.localStorage.getItem("group_id");
      app.agent_id = window.localStorage.getItem("agent_id");
      if (app.open_type === "group" || app.eprocess === "group") {
        app.getGroupHeaderInfo();
      } else {
        app.getAgentHeaderInfo();
      }
    },
    externalsite() {
      let app = this;
      if (app.open_type === "group" || app.eprocess === "group") {
        app.getGroupHeaderInfo();
      } else {
        app.getAgentHeaderInfo();
      }
    },
  },
};
</script>
<style lang="scss">
#accessCode-pop {
  .modal-dialog {
    max-width: 650px !important;
  }
  #modal-header {
    padding-top: 0px !important;
  }
}

.group-not-employer-container {
  p {
    color: #dc3545;
    cursor: pointer;
    margin-bottom: 0;
    font-size: 14px;
  }
  &:hover {
    text-decoration: underline;
    color: #dc3545;
  }
}
.copyright {
  color: #777;
}
.access-code-wrap {
  display: grid;
  margin: 0px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  .pop-title {
    font-size: 20px;
    text-align: center;
    color: #12404d;
    font-weight: bold;
  }
  img {
    margin: auto;
    height: 100px;
    margin-bottom: 1rem;
  }
  p {
    width: auto;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin-top: 0.5rem;
  }
  input {
    padding: 0.5rem 1rem;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    outline: none;
    margin-bottom: 1rem;
  }
}
.logo-align {
  margin-top: 30px
}
</style>

